'use client'

import { setIsomorphicCookie } from '@rentpath/cookies'
import { useCallback, useEffect, useMemo } from 'react'
import type { AppBannerProps } from './app-banner'
import { AppBanner } from './app-banner'

import { APP_BANNER_COOKIE_KEY } from './app-banner.const'
import styles from './app-banner.module.css'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import { searchComboboxOpened } from '../search/search-combobox/search-combobox.store'
import { useAppBanner } from './app-banner.context'
import { yieldOrContinue } from 'main-thread-scheduling'

const ONE_DAY_IN_SECONDS = 86_400

export function AppBannerSection(props: Omit<AppBannerProps, 'onClose'>) {
  const { dataTagSection } = props
  const { isAppBannerShowing, setIsAppBannerShowing } = useAppBanner()

  const isSearchComboboxOpened = useAtomValue(searchComboboxOpened)

  const onAppBannerLoad = useCallback(
    async function onAppBannerLoad() {
      await yieldOrContinue('idle')
      window.eventTracker?.track('impression', {
        non_interaction: true,
        section: props.dataTagSection,
      })
    },
    [props.dataTagSection]
  )

  useEffect(() => {
    if (isAppBannerShowing) {
      void onAppBannerLoad()
    }
  }, [onAppBannerLoad, isAppBannerShowing])

  const closeBanner = useCallback(
    async function closeBanner() {
      setIsAppBannerShowing(false)

      await yieldOrContinue('idle')
      setIsomorphicCookie(APP_BANNER_COOKIE_KEY, 'true', undefined, {
        maxAge: ONE_DAY_IN_SECONDS,
      })

      await yieldOrContinue('idle')
      window.eventTracker?.track('click', {
        section: dataTagSection,
        non_interaction: false,
        item: 'x',
      })
    },
    [dataTagSection, setIsAppBannerShowing]
  )

  const memoizedDeepLinkParams = useMemo(
    () => props.deepLinkParams,
    [props.deepLinkParams]
  )

  if (!isAppBannerShowing) {
    return null
  }

  return (
    // don't remove this wrapper, it prevents CLS
    <div
      className={clsx(
        styles.appBannerWrapper,
        isSearchComboboxOpened ? styles.menuOpened : styles.menuClosed
      )}
      id="mobile-app-banner-wrapper"
    >
      <AppBanner
        onClose={closeBanner}
        dataTagSection={props.dataTagSection}
        deepLinkParams={memoizedDeepLinkParams}
      />
    </div>
  )
}

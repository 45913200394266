'use client'

import { useOpenModal } from '../../modals/use-open-modal'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { ModalName } from '../../modals/modals.config'

import type { Dispatch, SetStateAction } from 'react'
import type { SearchComboBoxItem } from '../../search/search-combobox/search-combobox.types'

export interface OnSuccessCallbackParams {
  res?: {
    places?: SearchComboBoxItem[]
    properties?: SearchComboBoxItem[]
    savedSearches?: SearchComboBoxItem[]
  }
  setSuppressDropdown: Dispatch<SetStateAction<boolean>>
}

export function useShowOnboardingModal() {
  const onboardingIsActive =
    useFeatureVariable<number>(['homepage_filters', 'version'], 0) !== 0

  const openModal = useOpenModal()
  const openOnboardingModal = (selectedItem: SearchComboBoxItem) => {
    openModal({
      id: ModalName.ONBOARDING,
      props: { selectedItem },
    })
  }

  return function onSuccessCallback({
    res,
    setSuppressDropdown,
  }: OnSuccessCallbackParams) {
    // We only want to open onboarding if res has a place, we don't care about properties
    const firstPlace = res?.places?.[0] ?? res?.savedSearches?.[0]
    if (onboardingIsActive && firstPlace) {
      openOnboardingModal(firstPlace)
      // We never want to show the search results after the onboarding modal is opened
      setSuppressDropdown(true)
    }
  }
}
